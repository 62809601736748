/*globals $*/
'use strict';

const getValidationFunctions = () => import("./validate");

$( document ).ready(function() {

    /*General*/
    if($('#defaultOpen').length > 0) {
        document.getElementById("defaultOpen").click();
    }


    /*Form Artist*/
    $('#checkArtist').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateArtistForm();
        });
    });

    $('input[name=equipment]').on('change', function () {
        var value = $('input[name=equipment]:checked').val();

        if(value == '1'){
            $('#section_equipment_description').removeClass('hidden');
        }else{
            $('#section_equipment_description').addClass('hidden');
        }
    });

    $('#addOtherVideo').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateVideoForm();
        });
    });

    $('#addOtherMember').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateMemberForm();
        });
    });

    if($('#artist_members').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteMember")){
            $('#member-item-' + $(this).attr('data-id')).remove();
        }
        // e.preventDefault();
    }));

    $('#addContract').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateContractForm();
        });
    });

    $('#addPayment').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validatePaymentForm();
        });
    });

    $($('[id^="payment_status_"]')).on('change', function () {
        var value = $(this).val();
        var id =$(this).data('id');
        var url = $('#go-update-payments').val();
        url = url.slice(0, -1) + id;

        var formData = new FormData();

        formData.append('status', value);


        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: "POST",
            url: url,
            data: formData,
            contentType: false,
            processData: false,
            success: function(data) {
                data = JSON.parse(data);

                if(data['response'] == 'success') {
                    $('#success_message').removeClass('message-hidden');
                    setTimeout(function() {
                        $('#success_message').addClass('message-hidden')
                    }, 4000);
                }else {

                    $('#error_message').removeClass('message-hidden');
                    setTimeout(function() {
                        $('#error_message').addClass('message-hidden')
                    }, 4000);
                }


            },
            error: function(xhr, status, error) {
                var err = eval("(" + xhr.responseText + ")");
                $('#error_message').removeClass('message-hidden');
                setTimeout(function() {
                    $('#error_message').addClass('message-hidden')
                }, 4000);
            }
        });
    });


    /*Common*/
    $('#addOtherGenre').on('click', function(e){
        e.preventDefault();

        $('#otherGenre').removeClass('hidden');
        $('#addOtherGenre').addClass('hidden');

    });


    /*Releases*/
    $('#checkRelease').on('click', function(e){
        $('#status').val('published');

        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateReleaseForm();
        });
    });

    $('#checkReleaseDraft').on('click', function(e){
        $('#status').val('draft');

        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateReleaseForm();
        });
    });

    $('#addOtherTrack').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateTrackForm();
        });
    });

    $('#checkTrack').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateTrackForm();
        });
    });

    if($('#tracks').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteTrack")){
            $('#track-item-' + $(this).attr('data-id')).remove();
        }
        // e.preventDefault();
    }));

    $('.openMedia').on('click', function(){
        $('#media-info').css('display', 'block');
    });

    $('#media-section-close').on('click', function() {
        $('#media-info').css('display', 'none');
    });

    $('#addOtherConcert').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateConcertForm();
        });
    });

    if($('#concerts').on('click', 'a', function(e){
        var id = this.id;
        if(id.match("^deleteConcert")){
            $('#concert-section-' + $(this).attr('data-id')).remove();
        }
        // e.preventDefault();
    }));

    $('input[name=official_video]').on('change', function () {
        var value = $('input[name=official_video]:checked').val();

        if(value == '1'){
            $('#section_official_video_date').removeClass('hidden');
        }else{
            $('#section_official_video_date').addClass('hidden');
        }
    });

    $('#addOtherRights').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateRightForm();
        });
    });

    if($('#rights').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteRightMember")){
            $('#member-item-' + $(this).attr('data-id')).remove();
        }
        // e.preventDefault();
    }));


    //Songs
    $('#addAuthor').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateAuthorForm();
        });
    });

    if($('#track_authors').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteAuthor")){
            $('#author-item-' + $(this).attr('data-id')).remove();
            $('#royalties_author_' + $(this).attr('data-id')).remove();
        }
    }));

    $('#addComposer').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateComposerForm();
        });
    });

    if($('#track_composers').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteComposer")){
            $('#composer-item-' + $(this).attr('data-id')).remove();
            $('#royalties_composer_' + $(this).attr('data-id')).remove();
        }
    }));

    $('#addArranger').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateArrangerForm();
        });
    });

    if($('#track_arrangers').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteArranger")){
            $('#arranger-item-' + $(this).attr('data-id')).remove();
            $('#royalties_arranger_' + $(this).attr('data-id')).remove();
        }
    }));

    $('#addProducer').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateProducerForm();
        });
    });

    if($('#track_producers').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteProducer")){
            $('#producer-item-' + $(this).attr('data-id')).remove();
            $('#royalties_producer_' + $(this).attr('data-id')).remove();
        }
    }));

    $('input[name=isrc_generation]').on('change', function () {
        var value = $('input[name=isrc_generation]:checked').val();

        if(value == '0'){
            $('#track_isrc').removeClass('hidden');
        }else{
            $('#track_isrc').addClass('hidden');
        }
    });

    $('input[id^="download_"]').on('click', function () {
        if($('input[id^="download_"]:checked').length > 0) {
            $('#download_contracts').removeClass('hidden');
        }else{

            $('#download_contracts').addClass('hidden');
        }
    });

    $('#add_other_right').on('click', function(e){
        if($('#add_other_right_section').hasClass('hidden')) {
            $('#add_other_right_section').removeClass('hidden')
        }else{
            $('#add_other_right_section').addClass('hidden')
        }
    });

    $('#addOtherRight').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateOtherRightForm();
        });
    });

    /*Quan els links a datatable no funciona*/
    $('body').on('click', '[id^="delete_element-"]', function (e) {
        var url = $(this).attr('data-url');
        $('#element_delete_link').attr("href", url);
    });


    /*Form Rightholder*/
    $('#checkRightholder').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateRightholderForm();
        });
    });

    $('#addOtherRightholder').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateOtherRightholderForm();
        });
    });

    if($('#rightholders').on('click', 'a', function(e){
        var id = this.id;
        if(id.match("^deleteRightholder")){
            $('#rightholder-section-' + $(this).attr('data-id')).remove();
        }
        // e.preventDefault();
    }));

    /*Datasets*/
    $('#checkReleasesReport').on('click', function(e){
        e.preventDefault();
        var type = $(this).attr('data-type');
        $('#type').val(type);
        getValidationFunctions().then(validateModule => {
            validateModule.validateDatasetImport();
        });
    });

    $('#checkTracksReport').on('click', function(e){
        e.preventDefault();
        var type = $(this).attr('data-type');
        $('#type').val(type);
        getValidationFunctions().then(validateModule => {
            validateModule.validateDatasetImport();
        });
    });

    $('#checkSalesReport').on('click', function(e){
        e.preventDefault();
        var type = $(this).attr('data-type');
        $('#type').val(type);
        getValidationFunctions().then(validateModule => {
            validateModule.validateDatasetImport();
        });
    });

    $('#checkRichholdersRoyalties').on('click', function(e){
        e.preventDefault();
        var type = $(this).attr('data-type');
        $('#type').val(type);
        getValidationFunctions().then(validateModule => {
            validateModule.validateDatasetImport();
        });
    });

    $('#checkGroupRoyalties').on('click', function(e){
        e.preventDefault();
        var type = $(this).attr('data-type');
        $('#type').val(type);
        getValidationFunctions().then(validateModule => {
            validateModule.validateDatasetImport();
        });
    });

    $('#deselect_option').on('click', function(){
        $('[id^="artist_"]').prop('checked', false);
        var multiselect = document.getElementById("mySelectLabel");
        var multiselectOption = multiselect.getElementsByTagName('option')[0];
        multiselectOption.innerText = '';

        // $.each($('[id^="artist_"]'), function(index, value) {
        //     this.checked = false;
        // });
    });
});

